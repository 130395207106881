import React from 'react';
import classnames from 'classnames';
import './dropdown.scss';
import {
  string,
  bool,
  func
} from 'prop-types';
import { DropDown } from '@thd-olt-component-react/select';
import { DropdownItem } from './dropdown-item';

export const Dropdown = (props) => {
  const {
    isAvailable,
    onClick,
    value,
    swatchGuid
  } = props;

  const dropdownItemClasses = classnames({
    'super-sku__inline-dropdown-item': !swatchGuid,
    'super-sku__inline-swatch': swatchGuid,
    'super-sku__inline-dropdown-item--disabled': !(swatchGuid || isAvailable),
    'super-sku__inline-swatch--disabled': swatchGuid && !isAvailable
  });

  return (
    <DropDown.Item
      key={value}
      onClick={onClick}
    >
      <span className={dropdownItemClasses}>
        <DropdownItem swatchGuid={swatchGuid} attributeValue={value} />
      </span>
    </DropDown.Item>
  );
};

Dropdown.displayName = 'Dropdown';

Dropdown.propTypes = {
  value: string.isRequired,
  isAvailable: bool.isRequired,
  swatchGuid: string,
  onClick: func.isRequired
};

Dropdown.defaultProps = {
  swatchGuid: null
};
