import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DropDownItem extends Component {

  handleClick = (element) => {
    const { hideOnSelection, onClick } = this.props;
    if (onClick) {
      onClick(element);
    }
    if (hideOnSelection) {
      hideOnSelection();
    }
  };

  render() {
    const { children } = this.props;
    return (
      <span
        role="button"
        tabIndex={0}
        className="drop-down__hover-effect"
        onClick={this.handleClick}
        onKeyPress={this.handleClick}
      >
        {children}
      </span>
    );
  }
}

DropDownItem.propTypes = {
  /** Optional text to right of checkbox */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  hideOnSelection: PropTypes.func,
  onClick: PropTypes.func
};

DropDownItem.defaultProps = {
  children: null,
  hideOnSelection: null,
  onClick: null
};

export default DropDownItem;
