import React, { useState } from 'react';
import classnames from 'classnames';
import './tile.scss';
import {
  string,
  shape,
  bool,
  func,
} from 'prop-types';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { TooltipContent } from '../tooltip-content/tooltip-content';

export const Tile = (props) => {
  const {
    isSelected,
    isAvailable,
    isFabricType,
    value,
    onClick,
    onMouseEnter,
    onMouseLeave,
    isTooltipsEnabled,
    hoveredCombinationDetails,
    channel
  } = props;
  const [isHovered, setIsHovered] = useState(false);

  const shouldShowTooltip = isHovered && isTooltipsEnabled && hoveredCombinationDetails?.combinationDetails;

  const tileClass = classnames('super-sku__inline-tile border-radius--medium', {
    'super-sku__inline-tile--selected': isSelected,
    'super-sku__inline-tile--disabled': !isAvailable && !isFabricType,
  });

  const mouseEnter = (event) => {
    setIsHovered(true);
    onMouseEnter(event);
  };
  const mouseLeave = (event) => {
    setIsHovered(false);
    onMouseLeave(event);
  };

  const tileChild = (
    <div
      className="super-sku__inline-tile--space"
      role="presentation"
      onClick={onClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <button
        type="button"
        className={tileClass}
      >
        {value}
      </button>
    </div>
  );

  return (shouldShowTooltip
    ? (
      <Tooltip
        channel={channel}
        content={<TooltipContent hoveredCombinationDetails={hoveredCombinationDetails} />}
        interactive
      >
        {tileChild}
      </Tooltip>
    )
    : (tileChild)
  );
};

Tile.displayName = 'Tile';

Tile.propTypes = {
  channel: string,
  value: string.isRequired,
  isAvailable: bool.isRequired,
  isFabricType: bool,
  isSelected: bool.isRequired,
  onClick: func.isRequired,
  onMouseEnter: func.isRequired,
  onMouseLeave: func.isRequired,
  isTooltipsEnabled: bool,
  hoveredCombinationDetails: shape({})
};

Tile.defaultProps = {
  channel: 'desktop',
  hoveredCombinationDetails: {},
  isFabricType: false,
  isTooltipsEnabled: false
};
