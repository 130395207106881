import React, { useState, Suspense } from 'react';
import { Skeleton, Link, SkeletonLine } from '@one-thd/sui-atomic-components';
import { Info } from '@one-thd/sui-icons';
import { bool, string, shape } from 'prop-types';
import {
  alias,
  arrayOf,
  params,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources';
import { publish } from './publisher';
import { useSizeGuide } from './useSizeGuide';

// eslint-disable-next-line max-len
const SizeGuideOverlayContent = React.lazy(() => import(/* webpackChunkName: "size-guide-overlay" */ './components/size-guide-overlay-content/size-guide-overlay-content'));

export const SizeGuide = (props) => {
  const {
    itemId,
    isCollection,
    isSuperSku,
    productType,
    superSkuSizeAndFitDetail,
    itemSizeAndFitDetail,
    storeIdFromMock,
    channelFromMock
  } = props;

  const [open, setOpen] = useState(false);

  const { data: sizeGuideResponse, loading, error } = useSizeGuide({
    itemId,
    isCollection,
    isSuperSku,
    superSkuSizeAndFitDetail,
    itemSizeAndFitDetail,
    storeIdFromMock
  }) || {};

  if (error && !sizeGuideResponse) {
    return null;
  }
  if (loading) {
    return (
      <div data-component="SizeGuidePlaceholder">
        <Skeleton grow>
          <SkeletonLine fullWidth variant="single" />
        </Skeleton>
      </div>
    );
  }

  const handleSizeGuideClick = () => {
    setOpen(true);
    publish('size-guide.click', {});
  };

  if (!sizeGuideResponse) {
    return null;
  }

  return (
    <div data-component="SizeGuide">
      <div className="sui-inline-flex sui-items-center sui-gap-1 sui-h-9">
        <Info data-testid="info-icon" size="small" />
        <Link // eslint-disable-line
          component="button"
          onClick={handleSizeGuideClick}
        >
          Size Guide
        </Link>
      </div>
      {open && (
        <Suspense fallback={(
          <div data-component="SizeGuideOverlayContentPlaceholder">
            <SkeletonLine variant="single" numberOfLines={2} />
          </div>
        )}
        >
          <SizeGuideOverlayContent
            productType={productType}
            sizeGuideResponse={sizeGuideResponse}
            channelFromMock={channelFromMock}
            onClose={() => setOpen(false)}
            open={open}
          />
        </Suspense>
      )}
    </div>
  );
};

SizeGuide.displayName = 'SizeGuide';

SizeGuide.propTypes = {
  channelFromMock: string,
  isCollection: bool,
  isSuperSku: bool,
  itemId: string.isRequired,
  itemSizeAndFitDetail: shape({}),
  productType: string,
  superSkuSizeAndFitDetail: shape({}),
  storeIdFromMock: string
};

SizeGuide.defaultProps = {
  channelFromMock: null,
  isCollection: false,
  isSuperSku: false,
  itemSizeAndFitDetail: {},
  productType: null,
  superSkuSizeAndFitDetail: {},
  storeIdFromMock: null
};

SizeGuide.dataModel = {
  clientOnlyProduct: alias('product').params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    sizeAndFitDetail: shapeType({
      attributeGroups: arrayOf(shapeType({
        dimensionLabel: stringType(),
        attributes: arrayOf(shapeType({
          attributeName: stringType(),
          dimensions: stringType()
        })),
        productType: stringType()
      }))
    })
  }),
  collectionSizeAndFit: alias('collection').params({
    collectionId: stringType().isRequired(), storeId: stringType()
  }).shape({
    sizeAndFitDetail: shapeType({
      collectionName: stringType(),
      attributeGroups: arrayOf(shapeType({
        dimensionLabel: stringType(),
        attributes: arrayOf(shapeType({
          attributeName: stringType(),
          dimensions: stringType()
        })),
        productType: stringType()
      }))
    })
  })
};
