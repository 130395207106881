import React from 'react';
import { string, shape, arrayOf, bool } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import classnames from 'classnames';
import './tooltip-content.scss';

export const TooltipContent = (props) => {
  const {
    hoveredCombinationDetails: {
      image,
      combinationDetails = [],
      pricing
    } = {}
  } = props;

  const {
    message,
    shouldHidePriceForMap,
    isSpecialPrice,
    strikeThroughPriceForMap,
    price,
    unitValue,
    UOM,
    caseUOM
  } = pricing || {};

  let { formatted } = price || {};
  if (unitValue && caseUOM && caseUOM === 'sq. ft.') {
    formatted = `$${unitValue.toFixed(2)} /${caseUOM} (${formatted} /${UOM})`;
  }

  const priceWrapperClasses = classnames('u__bold', {
    'u--hide': shouldHidePriceForMap,
    'u__text--success': isSpecialPrice
  });
  const priceContainerClasses = classnames({
    'body__line-through': strikeThroughPriceForMap
  });

  return (
    <div className="super-sku__inline-tooltip">
      <span>
        <Image
          className="super-sku__inline-tooltip-image"
          src={image}
          alt="swatch"
          width="100"
          height="100"
        />
      </span>

      {(combinationDetails || []).map((attribute, attributeIndex) => {
        const attributeLabel = attribute.shouldDisplayAttributeLabel && `${attribute.attributeLabel}:`;
        const attributeLabelValue = attribute.attributeValue && attribute.attributeValue.split(',')[0];
        return (
          <div
            key={`${attributeLabelValue}-${attributeIndex}`}
            className="super-sku__inline-tooltip-attribute-info"
          >
            {attributeLabel} {attributeLabelValue}
          </div>
        );
      })}
      {(formatted || message)
        && (
          <>
            {formatted && (
              <div className={priceWrapperClasses}>
                <div className={priceContainerClasses}>
                  {formatted}
                </div>
              </div>
            )}
            {message && (
              <div className="u__legal">
                {message}
              </div>
            )}
          </>
        )}
    </div>
  );
};

TooltipContent.displayName = 'TooltipContent';

TooltipContent.propTypes = {
  hoveredCombinationDetails: shape({
    image: string,
    combinationDetails: arrayOf(shape({
      attributeLabel: string,
      attributeValue: string,
      shouldDisplayAttributeLabel: bool
    })),
    pricing: shape({
      message: string,
      price: shape({
        formatted: string,
      })
    })
  }).isRequired
};
