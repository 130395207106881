import React from 'react';
import { bool } from 'prop-types';
import './alerts.scss';

export const Alerts = (props) => {
  const {
    jumpedState,
    impossibleState,
  } = props;

  return (
    <>
      {(jumpedState || impossibleState)
        && (
          <div className="inline-alert alert-inline alert-inline--warning">
            <span className="alert-inline__message">
              <span className="alert-inline__title">
                {jumpedState && (
                  <div>
                    Your Selections have been updated based on availability
                  </div>
                )}

                {impossibleState && (
                  <div>
                    <span className="alert-inline__title">
                      We had some trouble loading your options.
                    </span>
                    <br />Select any option to get started.
                  </div>
                )}
              </span>
            </span>
          </div>
        )}
    </>
  );
};

Alerts.displayName = 'SuperSkuAlerts';

Alerts.propTypes = {
  jumpedState: bool,
  impossibleState: bool
};

Alerts.defaultProps = {
  jumpedState: false,
  impossibleState: false
};
