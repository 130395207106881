import {
  selectNewAttribute,
  initializeState,
  updateOverflowValues,
  resetOverflowValues,
  updateSdskuOverflowValues,
  resetSdskuOverflowValues,
  updateChildItemsLookupWithMediaPriceAndInventory,
  hoverAttribute,
  createErrorState,
  createNoDataState,
  createLoadingState,
  selectNewSdskuAttribute,
  resetHover,
  updateAlertValues,
  updateSelectedProductInfo
} from './reducer-util';
import { ACTION_TYPES } from '../statics';

export function reducer(state, action) {
  switch (action.type) {
  case ACTION_TYPES.LOAD_STATE:
    return initializeState(state, action);
  case ACTION_TYPES.ADD_MEDIA_PRICE_AND_INVENTORY:
    return updateChildItemsLookupWithMediaPriceAndInventory(state, action);
  case ACTION_TYPES.HOVER:
    return hoverAttribute(state, action);
  case ACTION_TYPES.HOVER_RESET:
    return resetHover(state);
  case ACTION_TYPES.ERROR_STATE:
    return createErrorState();
  case ACTION_TYPES.NO_DATA_STATE:
    return createNoDataState();
  case ACTION_TYPES.LOADING_STATE:
    return createLoadingState(state);
  case ACTION_TYPES.SELECT:
    return selectNewAttribute(state, action);
  case ACTION_TYPES.RESET_OVERFLOW_VALUES:
    return resetOverflowValues(state, action);
  case ACTION_TYPES.UPDATE_OVERFLOW_VALUES:
    return updateOverflowValues(state, action);
  case ACTION_TYPES.RESET_SDSKU_OVERFLOW_VALUES:
    return resetSdskuOverflowValues(state, action);
  case ACTION_TYPES.UPDATE_SDSKU_OVERFLOW_VALUES:
    return updateSdskuOverflowValues(state, action);
  case ACTION_TYPES.SDSKU_SELECT:
    return selectNewSdskuAttribute(state, action);
  case ACTION_TYPES.UPDATE_ALERT_VALUES:
    return updateAlertValues(state, action);
  case ACTION_TYPES.SELECTED_PRODUCT:
    return updateSelectedProductInfo(state, action);
  default:
    return state;
  }
}
