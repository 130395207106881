import React from 'react';
import { arrayOf, string, shape, bool } from 'prop-types';
import { SizeGuide } from '@thd-olt-component-react/size-guide';
import { AttributeLabelModal } from './attribute-label-modal';
import './attribute-label.scss';

export const AttributeLabel = (props) => {
  const {
    attributeName,
    attributeLabelValue,
    brandName,
    hexColor,
    info,
    paintType,
    isPaintReorder,
    sizeGuideInfo,
    containerSizeHref
  } = props;
  const {
    parentId,
    collectionId,
    itemId,
    superSkuSizeAndFitDetail = {},
    itemSizeAndFitDetail = {}
  } = sizeGuideInfo || {};

  const hasSizeGuideAttributeGroups = !!itemSizeAndFitDetail?.attributeGroups?.length;

  const hasSizeGuide = () => {
    return attributeName?.toLowerCase() === 'size' && hasSizeGuideAttributeGroups;
  };

  const productType = itemSizeAndFitDetail?.attributeGroups?.[0]?.productType;
  const superSkuIdOrItemId = hasSizeGuideAttributeGroups ? parentId : itemId;
  const sizeGuideItemId = collectionId || superSkuIdOrItemId;
  const isWallPaint = info?.paintFamily === 'Wall Paint';
  const showAttributeModal = isPaintReorder ? isWallPaint : !!info?.dotComColorEligible;
  const isContainerSize = attributeName?.toLowerCase() === 'container size';

  const scrollToWallAreaCalculator = (event) => {
    let wallAreaCalculator = document.getElementById(containerSizeHref);
    event.preventDefault();
    /* eslint-disable no-unused-expressions */
    wallAreaCalculator?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  };

  return (
    <div className="attribute__label">
      <div className="label">
        {attributeName}:
        {attributeLabelValue && <span className="attribute-value"> {attributeLabelValue}</span>}
      </div>
      {hasSizeGuide() && (
        <div className="size-guide-container">
          <SizeGuide
            itemId={sizeGuideItemId}
            productType={productType}
            isSuperSku={!collectionId}
            isCollection={!!collectionId}
            superSkuSizeAndFitDetail={superSkuSizeAndFitDetail}
            itemSizeAndFitDetail={itemSizeAndFitDetail}
          />
        </div>
      )}
      {showAttributeModal && !isContainerSize && (
        <div className="sui-mt-2">
          <AttributeLabelModal
            attributeName={attributeName}
            hexColor={hexColor}
            brandName={brandName}
            type={paintType}
          />
        </div>
      )}
      {isContainerSize && (
        <div className="sui-ml-3 sui-mt-2 sui-whitespace-nowrap">
          <button
            type="button"
            className="sui-h-6 sui-flex sui-flex-row sui-gap-1"
            onClick={scrollToWallAreaCalculator}
          >
            <span
              data-testid="container-size-link"
              className="sui-text-base sui-text-primary sui-underline"
            >
              Help Me Choose
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

AttributeLabel.displayName = 'AttributeLabel';

AttributeLabel.propTypes = {
  attributeName: string.isRequired,
  attributeLabelValue: string,
  brandName: string,
  hexColor: string,
  info: shape({
    paintBrand: string,
    paintFamily: string
  }),
  isPaintReorder: bool,
  paintType: string,
  sizeGuideInfo: shape({
    collectionId: string,
    itemSizeAndFitDetail: shape({
      attributeGroups: arrayOf(shape({}))
    }),
    parentId: string,
    productType: string,
    superSkuSizeAndFitDetail: shape({
      attributeGroups: arrayOf(shape({}))
    })
  }),
  containerSizeHref: string
};

AttributeLabel.defaultProps = {
  attributeLabelValue: '',
  brandName: '',
  hexColor: '',
  info: {
    paintBrand: ''
  },
  isPaintReorder: false,
  paintType: '',
  sizeGuideInfo: {
    collectionId: '',
    isSize: false,
    isTowelSize: false,
    parentId: '',
    productType: '',
    superSkuSizeAndFitDetail: {}
  },
  containerSizeHref: ''
};
