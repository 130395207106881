import React, {
  useLayoutEffect, useRef, useState, useContext, useEffect
} from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import classnames from 'classnames';
import './super-sku-attribute.scss';
import {
  string,
  func,
  bool,
  node,
  number,
  shape
} from 'prop-types';
import { DropDown } from '@thd-olt-component-react/select';
import { AttributeLabel } from '../attribute-label/attribute-label';
import { DropdownItem } from '../dropdown/dropdown-item';

export const SuperSkuAttribute = (props) => {
  const {
    attributeName,
    attributeValue,
    attributeType,
    brandName,
    children,
    center,
    handleAttributeOverflowReset,
    handleAttributeOverflowUpdate,
    isOverflowSection,
    info,
    paintType,
    isPaintReorder,
    scrollHeight,
    miniSwatch,
    sizeGuideInfo,
    swatchGuid,
    large,
    hexColor,
    itemId,
    containerSizeHref
  } = props;

  const { channel } = useContext(ExperienceContext);

  const isDropdown = attributeType === 'dropdown';
  const isSwatch = attributeType === 'swatch';
  const isCard = attributeType === 'card';
  const isTile = attributeType === 'tile';

  const attributeWrapperClasses = classnames({
    'super-sku__inline-attribute-wrapper': !isDropdown,
    'super-sku__inline-dropdown-section': isDropdown,
    'super-sku__inline-attribute-wrapper--swatch': isSwatch,
    'super-sku__inline-attribute-wrapper--card': isCard,
    'super-sku__inline-attribute-wrapper--dropdown-overflow': isTile && isOverflowSection,
    'super-sku__inline-attribute-wrapper--swatch--dropdown-overflow': isSwatch && isOverflowSection,
    'super-sku__inline-attribute-wrapper--tile': isTile,
    'super-sku__inline-attribute-wrapper--block': large && channel === 'desktop'
  });

  const attributeContainerClasses = classnames('super-sku__inline-attribute', {
    'super-sku__inline-attribute--center': center
  });

  const attributeRef = useRef(null);

  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0
  });

  useEffect(() => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    });
  }, []);

  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    });
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    if (handleAttributeOverflowReset) {
      handleAttributeOverflowReset(attributeRef?.current?.scrollHeight, attributeName);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dimensions, itemId]);

  useLayoutEffect(() => {
    if (handleAttributeOverflowUpdate) {
      handleAttributeOverflowUpdate(attributeRef, attributeName);
    }
  }, [scrollHeight, isOverflowSection]);

  return (
    !miniSwatch ? (
      <div className="super-sku__inline-attribute" data-component="SuperSkuAttribute">
        <AttributeLabel
          attributeName={attributeName}
          attributeLabelValue={attributeValue}
          brandName={brandName}
          sizeGuideInfo={sizeGuideInfo}
          hexColor={hexColor}
          info={info}
          paintType={paintType}
          isPaintReorder={isPaintReorder}
          containerSizeHref={containerSizeHref}
        />
        <div
          className={attributeWrapperClasses}
          ref={attributeRef}
        >
          {isDropdown
            ? (
              <DropDown
                header={<DropdownItem attributeValue={attributeValue} swatchGuid={swatchGuid} hideOverflow />}
                hideOnSelection
                expandOnClick
                className={swatchGuid && 'super-sku__drop-down--position'}
              >
                { children }
              </DropDown>
            )
            : children}
        </div>
      </div>
    )
      : !isDropdown && !isCard && (
        <div className={attributeContainerClasses} data-component="SuperSkuAttributeMini">
          <div className="swatch-container">
            {children}
          </div>
        </div>
      )
  );
};

SuperSkuAttribute.propTypes = {
  attributeName: string.isRequired,
  attributeValue: string,
  attributeType: string.isRequired,
  brandName: string,
  children: node.isRequired,
  center: bool,
  handleAttributeOverflowReset: func,
  handleAttributeOverflowUpdate: func,
  hexColor: string,
  isOverflowSection: bool,
  info: shape({
    paintBrand: string,
  }),
  isPaintReorder: bool,
  large: bool,
  miniSwatch: bool,
  paintType: string,
  scrollHeight: number,
  sizeGuideInfo: shape({
    collectionId: string,
    isSize: bool,
    isTowelSize: bool,
    parentId: string,
    productType: string
  }),
  swatchGuid: string,
  itemId: string,
  containerSizeHref: string
};

SuperSkuAttribute.defaultProps = {
  attributeValue: null,
  brandName: '',
  center: false,
  handleAttributeOverflowReset: () => { },
  handleAttributeOverflowUpdate: () => { },
  hexColor: '',
  isOverflowSection: false,
  info: {
    paintBrand: '',
  },
  isPaintReorder: false,
  large: false,
  miniSwatch: false,
  paintType: '',
  scrollHeight: 0,
  sizeGuideInfo: {
    collectionId: '',
    isSize: false,
    isTowelSize: false,
    parentId: '',
    productType: ''
  },
  swatchGuid: '',
  itemId: '',
  containerSizeHref: ''
};
