import React, { useState } from 'react';
import { string } from 'prop-types';
import { Link, Modal, ModalBody, ModalHeader } from '@one-thd/sui-atomic-components';
import { PaintModalContent } from '@thd-olt-component-react/paint-modal-content';
import { publish } from '../../publisher';
import { getTitle } from '../../super-sku-util';

export const AttributeLabelModal = (props) => {
  const { attributeName, hexColor, brandName, type } = props;

  const [open, setOpen] = useState(false);

  const onModalClose = () => setOpen(false);

  const onModalClick = () => {
    setOpen(true);
    const labels = {
      'Color/Finish': 'Paint Types',
      'Paint Type': 'Paint Types',
      Sheen: 'Sheen Options',
      'Brand Name': 'Brand Options'
    };
    const data = {
      target: 'Help me choose',
      component: labels[attributeName],
      section: 'super-sku'
    };

    publish('click-help-me-choose', data);
  };

  const paintType = attributeName === 'Color/Finish' || attributeName === 'Paint Type';
  const sheen = attributeName === 'Sheen';
  const brand = attributeName === 'Brand Name';

  return (
    <>
      <p className="sui-ml-3 sui-flex sui-text-xs">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          component="button"
          onClick={onModalClick}
        >
          Help Me Choose
        </Link>
        <Modal
          open={open}
          onClose={onModalClose}
          size="lg"
        >
          <ModalHeader onClose={onModalClose}>{getTitle(attributeName, type)}</ModalHeader>
          <ModalBody className="sui-flex-auto sui-w-full">
            {paintType && <PaintModalContent.PaintType />}
            {brand && <PaintModalContent.Brand brandName={brandName} type={type} />}
            {sheen && <PaintModalContent.Sheen colorHex={`#${hexColor}`} />}
          </ModalBody>
        </Modal>
      </p>
    </>
  );
};

AttributeLabelModal.displayName = 'AttributeLabelModal';

AttributeLabelModal.propTypes = {
  attributeName: string.isRequired,
  brandName: string,
  hexColor: string,
  type: string
};

AttributeLabelModal.defaultProps = {
  brandName: '',
  hexColor: '',
  type: ''
};
