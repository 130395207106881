import React, { Component } from 'react';
import {
  arrayOf, bool, node, oneOfType, string
} from 'prop-types';
import classNames from 'classnames';
import DropDownItem from './DropDownItem';
import DropDownSearch from './DropDownSearch.component';
import './Dropdown.styles.scss';

class DropDown extends Component {
  static Item = DropDownItem;

  static Search = DropDownSearch;

  state = {
    hide: false,
    open: false
  };

  onSelectionChange = () => {
    this.setState({ hide: true });
  };

  dropdownItems = ({ hideOnSelection, items }) => {
    return items.map((item) => {
      return {
        ...item,
        props: {
          ...item.props,
          hideOnSelection: hideOnSelection ? this.onSelectionChange : null
        }
      };
    });
  };

  showList = () => {
    const {
      expandOnClick
    } = this.props;

    if (!expandOnClick) {
      this.setState({ hide: false });
    }
  };

  toggleOpen = () => {
    this.setState((state) => {
      return { open: !state.open };
    });
  };

  close = () => {
    this.setState(() => {
      return { open: false };
    });
  }

  render() {
    const {
      expandOnClick,
      header,
      hideOnSelection,
      children,
      className
    } = this.props;

    const {
      hide,
      open
    } = this.state;

    const isArray = Array.isArray(children);
    const type = isArray ? children[0].type : children.type;
    const isSearch = type === DropDownSearch;
    const kids = isArray ? children : [children].filter((child) => child);

    const wrapperClasses = classNames('drop-down__content-wrapper', className, {
      'drop-down__content-wrapper--hidden': !expandOnClick && hide,
    });

    const headerClasses = classNames({
      'drop-down__header': !expandOnClick,
      'drop-down__header--touch': expandOnClick,
      'drop-down__header--open': expandOnClick && open
    });

    const titleClasses = classNames('drop-down__title', className);

    return (
      <div
        className={headerClasses}
        onClick={this.toggleOpen}
        onMouseLeave={this.close}
        role="button"
        tabIndex={0}
      >
        <span className={titleClasses} onMouseEnter={this.showList}>{header}</span>
        <div className={wrapperClasses}>
          {isSearch
            ? children
            : this.dropdownItems({ hideOnSelection, items: kids })}

        </div>
      </div>
    );
  }
}

DropDown.propTypes = {
  /** Drop Down Items */
  children: oneOfType([
    node,
    arrayOf(node)
  ]).isRequired,
  className: string,
  expandOnClick: bool,
  hideOnSelection: bool,
  /** Header */
  header: oneOfType([
    node,
    string
  ])
};

DropDown.defaultProps = {
  className: null,
  expandOnClick: false,
  hideOnSelection: false,
  header: null,
};

export default DropDown;
