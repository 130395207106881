import React from 'react';
import PropTypes from 'prop-types';

function DropDownSearch(props) {
  return (
    <span className="form-input u--padding">
      <input
        className="form-input__field"
        id="searchItems"
        onChange={(event) => {
          props.onChange(event.target.value);
        }}
        placeholder="Search"
        type="text"
      />
    </span>
  );
}

DropDownSearch.propTypes = {
  /** Action to perform for checkbox click */
  onChange: PropTypes.func.isRequired
};

export default DropDownSearch;
