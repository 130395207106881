import React from 'react';
import classnames from 'classnames';
import { string, bool } from 'prop-types';
import './more-options.scss';

export const MoreOptions = ({ canonicalUrl, displayInTooltip }) => {
  const moreOptionsClass = classnames('super-sku__inline-swatch__mini-swatch__more-options', {
    'super-sku__inline-swatch__mini-swatch__more-options--in-tooltip': displayInTooltip
  });

  return (
    <a
      href={canonicalUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={moreOptionsClass}
    >
      More Options
    </a>
  );
};

MoreOptions.displayName = 'MoreOptions';

MoreOptions.propTypes = {
  canonicalUrl: string.isRequired,
  displayInTooltip: bool
};

MoreOptions.defaultProps = {
  displayInTooltip: false
};
