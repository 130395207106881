import React, { useEffect, useState, useReducer } from 'react';
import {
  func,
  string,
  shape,
  arrayOf
} from 'prop-types';
import { SuperSkuAttribute } from '../super-sku-attribute/super-sku-attribute';
import { SuperSkuAttributeValue } from '../super-sku-attribute-value/super-sku-attribute-value';
import { getAttributeType, initialState } from '../../hooks/useSuperSku';
import {
  ACTION_TYPES
} from '../../statics';
import { reducer } from '../../reducer/reducer';

export const CollectionsSuperSku = ({
  onChange,
  channel,
  attributes,
  collectionId: collectionIdProp
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    collectionId: collectionIdProp,
    disableMediaAndAvailability: false,
    attributes: [
      {
        attributeName: attributes.title || '',
        attributeValues: attributes.linkedCollections || []
      }
    ],
    childItemsLookup: []
  });

  const [collection, setCollection] = useState({});
  const [isSuperSkuMouseEntered, setSuperSkuMouseEntered] = useState(false);
  const [hoveredCollection, setHoveredCollection] = useState({});

  const convertStrHyphenLowercase = (data) => {
    return data.replace(/\s+/g, '-').toLowerCase();
  };

  const findSelectedCollectionByName = (data) => {
    return attributes?.linkedCollections?.find((value) => value.name === data);
  };

  const findSelectedCollectionById = (data) => {
    return attributes?.linkedCollections?.find((value) => convertStrHyphenLowercase(value.id) === data);
  };

  useEffect(() => {
    if (collectionIdProp && collectionIdProp !== collection?.id) {
      setCollection(findSelectedCollectionById(collectionIdProp));
    }
  }, [collectionIdProp, collection]);

  const handleSuperSkuSelect = (__, attributeValue) => {
    const selectedAttribute = findSelectedCollectionByName(attributeValue);
    setCollection(selectedAttribute);
    onChange({ collectionIdValue: convertStrHyphenLowercase(selectedAttribute.id) });
  };

  const handleSuperSkuMouseEnter = (__, attributeValue) => {
    const selectedAttribute = findSelectedCollectionByName(attributeValue);
    setHoveredCollection(selectedAttribute);
    setSuperSkuMouseEntered(true);
  };

  const handleSuperSkuMouseLeave = () => {
    setSuperSkuMouseEntered(false);
  };

  const handleAttributeOverflowReset = (scrollHeight, attributeName) => {
    dispatch({
      type: ACTION_TYPES.RESET_OVERFLOW_VALUES, scrollHeight, attributeName
    });
  };

  const handleAttributeOverflowUpdate = (attributeRef, attributeName) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_OVERFLOW_VALUES, attributeRef, attributeName
    });
  };

  if (attributes?.linkedCollections?.length === 0) {
    return null;
  }

  const attribute = state.attributes?.[0] || {};
  const { isOverflowSection, scrollHeight, disableMediaAndAvailability } = attribute;
  const attributeType = getAttributeType(
    state,
    channel,
    attribute,
    isOverflowSection,
    true, // hideCards
    disableMediaAndAvailability,
    false // large
  );

  return (
    <SuperSkuAttribute
      attributeName={attributes?.title}
      attributeValue={isSuperSkuMouseEntered ? hoveredCollection?.name : collection?.name}
      attributeType={attributeType}
      handleAttributeOverflowReset={handleAttributeOverflowReset}
      handleAttributeOverflowUpdate={handleAttributeOverflowUpdate}
      isOverflowSection={isOverflowSection}
      scrollHeight={scrollHeight}
    >
      {
        attributes?.linkedCollections?.map((attributeValue) => {
          const isSelectedAttributeValue = attributeValue?.id === collection.id;
          return (
            <SuperSkuAttributeValue
              value={attributeValue.name}
              isAvailable
              key={attributeValue?.id}
              isSelected={isSelectedAttributeValue}
              attributeType={attributeType}
              onClick={(event) => handleSuperSkuSelect(
                event,
                attributeValue.name
              )}
              onMouseEnter={(event) => handleSuperSkuMouseEnter(
                event,
                attributeValue.name
              )}
              onMouseLeave={(event) => handleSuperSkuMouseLeave(
                event,
                attributeValue.name
              )}
              channel={channel}
            />
          );
        })
      }
    </SuperSkuAttribute>
  );
};

CollectionsSuperSku.propTypes = {
  onChange: func.isRequired,
  channel: string.isRequired,
  attributes: shape({
    title: string,
    linkedCollections: arrayOf(shape(
      {
        id: string,
        name: string,
        swatch: string
      }
    ))
  }).isRequired,
  collectionId: string.isRequired
};
