import React from 'react';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import classnames from 'classnames/bind';
import { Row } from '@thd-olt-component-react/grid';
import { bool } from 'prop-types';
import styles from './loader.scss';

const classes = classnames.bind(styles);

export const Loader = (props) => {
  const { large } = props;

  return (
    <div className="super-sku-loading-container">
      {large
        ? (
          <>
            <Placeholder
              className="super-sku-loading__text-row"
              type="text"
              showLoadingAnimation
              rows={1}
              width="25%"
            />
            <Row>
              <Placeholder
                className="super-sku-loading__rectangle"
                type="rect"
                showLoadingAnimation
                width="120px"
                height="120px"
              />
              <Placeholder
                className="super-sku-loading__rectangle"
                type="rect"
                showLoadingAnimation
                width="120px"
                height="120px"
              />
            </Row>
          </>
        )
        : (
          <div className="super-sku-loading-wrapper">
            <Placeholder
              className="super-sku-loading__text-row"
              type="text"
              showLoadingAnimation
              rows={1}
              width="25%"
            />
            <Row>
              <Placeholder
                className="super-sku-loading__rectangle"
                type="rect"
                showLoadingAnimation
                width="120px"
                height="80px"
              />
              <Placeholder
                className="super-sku-loading__rectangle"
                type="rect"
                showLoadingAnimation
                width="120px"
                height="80px"
              />
              <Placeholder
                className="super-sku-loading__rectangle"
                type="rect"
                showLoadingAnimation
                width="120px"
                height="80px"
              />
              <Placeholder
                className="super-sku-loading__rectangle"
                type="rect"
                showLoadingAnimation
                width="120px"
                height="80px"
              />
            </Row>
          </div>
        )}
    </div>
  );
};

Loader.displayName = 'SuperSkuLoader';

Loader.propTypes = {
  large: bool,
};

Loader.defaultProps = {
  large: false,
};
