import React from 'react';
import { string, number, shape, bool } from 'prop-types';
import classnames from 'classnames/bind';
import styles from '../card.module.scss';

const classes = classnames.bind(styles);

export const CardPrice = ({ pricing }) => {

  const { isSpecialPrice, message, price, strikeThroughPriceForMap } = pricing || {};
  const { cents, dollars, formatted, lessThanDollar } = price || {};

  const strikeThruPriceContainerClasses = classes('super-sku__inline-card__map-was-price', {
    'body__line-through': strikeThroughPriceForMap
  });
  const priceContainerClasses = classes('super-sku__inline-card-price-container', {
    'u__text--success': isSpecialPrice
  });

  return message
    ? (
      <>
        <div className={strikeThruPriceContainerClasses}>
          {strikeThroughPriceForMap && formatted}
        </div>
        <div className={classes('super-sku__inline-card__map-message')}>
          See Price in Cart
        </div>
      </>
    ) : (
      <div className={priceContainerClasses}>
        <div className={classes('super-sku__inline-card__price')}>
          {lessThanDollar
            ? (
              <>
                <span>{cents}</span>
                <span className={classes('super-sku__inline-card__price--superscript')}>¢</span>
              </>
            )
            : (
              <>
                <span className={classes('super-sku__inline-card__price--superscript')}>$</span>
                <span>{dollars}</span>
                <span className={classes('super-sku__inline-card__price--superscript')}>{cents}</span>
              </>
            )}
        </div>
      </div>
    );
};

CardPrice.displayName = 'Card Price';

CardPrice.propTypes = {
  pricing: shape({
    isSpecialPrice: bool,
    message: bool,
    price: shape({
      cents: number,
      dollars: number,
      formatted: string,
      lessThanDollar: bool,

    }),
    strikeThroughPriceForMap: bool,
  }).isRequired,
};
