import React from 'react';
import './loading-error.scss';
import { func } from 'prop-types';

export const LoadingError = ({ onReloadClick }) => {

  return (
    <div className="super-sku-loading-error-wrapper">
      <div className="error-messaging alert-inline alert-inline--danger">
        <span className="alert-inline__message">
          <span className="alert-inline__title">We&#39;re sorry, </span>
          an error occurred while loading your options. Please refresh and try again.
        </span>
      </div>
      <div className="reload-button">
        <button
          type="button"
          className="bttn-outline bttn-outline--dark"
          onClick={() => onReloadClick()}
        >
          <span className="bttn__content">Reload Options</span>
        </button>
      </div>
    </div>
  );
};

LoadingError.propTypes = {
  onReloadClick: func.isRequired
};
