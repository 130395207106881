import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classnames from 'classnames/bind';
import { Image } from '@thd-olt-component-react/core-ui';
import { getPaintImageUrl, getPaintContainerSize } from '../../super-sku-util';
import { CardPrice } from './components/CardPrice';
import styles from './card.module.scss';

const classes = classnames.bind(styles);

export const Card = (props) => {
  const {
    isDotComColorEligible,
    isSelected,
    attributeValue,
    onClick,
    swatchCardCombinationDetails,
    centeredCards,
    hexColor,
    attributeName,
    paintBrand,
    isPaintReorder
  } = props;

  const { image, pricing } = swatchCardCombinationDetails || {};

  let imageUrl = image;
  let isSheen;
  let containerSize;
  if (isDotComColorEligible || isPaintReorder) {
    imageUrl = getPaintImageUrl(attributeValue, attributeName, paintBrand);
    isSheen = attributeName === 'Sheen';
    containerSize = getPaintContainerSize(attributeValue);
  }

  const NO_IMAGE_ATTRIBUTES = ['Sheen', 'Brand Name', 'Color/Finish', 'Paint Type'];
  const showImage = imageUrl || !NO_IMAGE_ATTRIBUTES.includes(attributeName);

  const cardContainerClasses = classes('super-sku__inline-card', {
    'super-sku__inline-card--selected': isSelected,
    'super-sku__inline-card--centered': centeredCards,
    'super-sku__inline-card--sheen': isSheen,
    'super-sku__inline-card--justify-center': centeredCards && !showImage,
  });

  const imageClasses = classes('super-sku__inline-card__image-wrapper', {
    'super-sku__inline-card__image-wrapper--sample': containerSize === 'sample',
    'super-sku__inline-card__image-wrapper--quart': containerSize === 'quart',
    'super-sku__inline-card__image-wrapper--gallon': containerSize === 'gallon',
    'super-sku__inline-card__image-wrapper--5gallon': containerSize === '5gallon',
  });

  const textClasses = classes('super-sku__inline-card__attribute-text', {
    'super-sku__inline-card__attribute-text--container-size': containerSize
  });

  const hasCombinationDetails = Object.keys(swatchCardCombinationDetails || {}).length !== 0;
  const loading = hasCombinationDetails && !imageUrl && showImage;

  if (loading) {
    return (
      <div className={cardContainerClasses}>
        <div className="loading-card" />
      </div>
    );
  }

  return (!hasCombinationDetails ? null
    : (
      <button type="button" className={cardContainerClasses} onClick={onClick}>
        { showImage && (
          <div
            className={imageClasses}
            style={isSheen ? { background: `#${hexColor}` } : undefined}
          >
            <Image src={imageUrl} alt={attributeValue} />
          </div>
        )}
        <div className={textClasses}>
          {attributeValue}
        </div>
        {pricing && <CardPrice pricing={pricing} />}
      </button>
    )
  );
};

Card.displayName = 'Card';

Card.propTypes = {
  attributeValue: string.isRequired,
  centeredCards: bool,
  hexColor: string,
  isDotComColorEligible: bool,
  isSelected: bool,
  onClick: func.isRequired,
  swatchCardCombinationDetails: shape({
    image: string,
    pricing: shape({
      message: string,
      isSpecialPrice: bool,
      strikeThroughPriceForMap: bool,
      price: shape({
        formatted: string,
        dollars: string,
        cents: string,
        lessThanDollar: bool
      })
    })
  }).isRequired,
  attributeName: string,
  paintBrand: string,
  isPaintReorder: bool,
};

Card.defaultProps = {
  centeredCards: false,
  hexColor: '',
  isDotComColorEligible: false,
  isSelected: false,
  attributeName: '',
  isPaintReorder: false,
  paintBrand: ''
};
