import React, { useState } from 'react';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import {
  arrayOf, bool, func, oneOf, shape, string, number
} from 'prop-types';
import classNames from 'classnames';
import { NUMBER_OF_DROPDOWN_ITEMS_FOR_VIEW_MORE,
  WIDTH_OF_TOOLTIP } from '../../statics';
import { MoreOptions } from '../more-options/more-options';
import { SuperSkuAttributeValue } from '../super-sku-attribute-value/super-sku-attribute-value';
import './mini-swatch-tooltip.scss';

export const MiniSwatchTooltip = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const caretRef = React.useRef();
  const {
    attributeName,
    attributeType,
    attributeValues,
    canonicalUrl,
    channel,
    handleAttributeValueClick,
    handleAttributeValueHover,
    handleResetHover,
    isOverflowSection,
    isTooltipsEnabled,
    selectedAttribute
  } = props;

  const miniSwatchTooltipHandler = () => {
    setIsOpen(!isOpen);
  };

  const onAttributeValueClick = (event, value) => {
    if (handleAttributeValueClick) {
      handleAttributeValueClick(
        event,
        attributeName,
        value
      );
    }
    if (caretRef && caretRef.current) {
      caretRef.current.click();
    }
  };

  const miniSwatchToolTipContent = (
    <div className="super-sku__inline-swatch__mini-swatch__tooltip-wrapper">
      <div className="super-sku__inline-swatch__mini-swatch__swatch-wrapper">
        {attributeValues.map((attributeValue) => {
          const { value, swatchGuid } = attributeValue || {};
          return (
            <SuperSkuAttributeValue
              attributeType={attributeType}
              channel={channel}
              displayInToolTip
              isAvailable
              isOverflowSection={isOverflowSection}
              isSelected={selectedAttribute === value}
              isTooltipsEnabled={isTooltipsEnabled}
              key={`${attributeName}-${value}`}
              miniSwatch
              onClick={(event) => onAttributeValueClick(event, value)}
              onMouseEnter={((event) => handleAttributeValueHover(event,
                attributeName,
                value,
                isTooltipsEnabled,
                true
              ))}
              onMouseLeave={handleResetHover}
              swatchGuid={swatchGuid}
              value={value}
            />
          );
        })}
      </div>
      {attributeValues.length > NUMBER_OF_DROPDOWN_ITEMS_FOR_VIEW_MORE
      && canonicalUrl
      && <MoreOptions canonicalUrl={canonicalUrl} displayInTooltip />}
    </div>
  );
  const caretClasses = classNames('super-sku__mini-swatches__caret', {
    'super-sku__mini-swatches__caret--open': isOpen
  });

  return (
    <Tooltip
      channel="desktop"
      className="thd-react-tooltip__content--no-padding"
      content={<div>{miniSwatchToolTipContent}</div>}
      interactive
      maxWidth={WIDTH_OF_TOOLTIP}
      placement="bottom"
      trigger="click"
      closeButton
      showClose={false}
    >
      <div
        ref={caretRef}
        /* eslint-disable-next-line react/no-unknown-property */
        onClose={miniSwatchTooltipHandler}
        onClick={miniSwatchTooltipHandler}
        className={caretClasses}
        data-testid="mini-swatches"
        role="button"
        tabIndex={0}
        aria-label="caret"
      />
    </Tooltip>
  );
};

MiniSwatchTooltip.displayName = 'MiniSwatchTooltip';

MiniSwatchTooltip.propTypes = {
  attributeName: string.isRequired,
  attributeType: string.isRequired,
  attributeValues: arrayOf(shape({})).isRequired,
  canonicalUrl: string.isRequired,
  channel: oneOf(['desktop', 'mobile']).isRequired,
  handleAttributeValueClick: func.isRequired,
  handleAttributeValueHover: func.isRequired,
  handleResetHover: func.isRequired,
  isOverflowSection: bool.isRequired,
  isTooltipsEnabled: bool.isRequired,
  selectedAttribute: string.isRequired
};
