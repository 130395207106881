import { brandNames } from '../../../data/paint-modals';

export const validateBrandData = (brandData) => {
  if (!brandData) {
    return { error: 'Must have brand data.', success: false };
  }

  if (!brandData.every((brand) => brand.name)) {
    return { error: 'Every brand needs a name.', success: false };
  }

  if (!brandData.every((brand) => brand.image)) {
    return { error: 'Every brand needs an image.', success: false };
  }

  const firstBrand = brandData[0];
  const firstBrandKeys = Object.keys(firstBrand);
  for (let brandIndex = 1; brandIndex < brandData.length; brandIndex += 1) {
    const currentBrandKeys = Object.keys(brandData[brandIndex]);

    if (currentBrandKeys.length !== firstBrandKeys.length) {
      return {
        error: `${brandData[brandIndex].name}: does not have the required amount of properties.`,
        success: false
      };
    }
    for (let keyIndex = 0; keyIndex < firstBrandKeys.length; keyIndex += 1) {
      if (firstBrandKeys[keyIndex] !== currentBrandKeys[keyIndex]) {
        return {
          error: `${brandData[brandIndex].name}.${currentBrandKeys[keyIndex]} doesn't match. `
            + `Expected ${brandData[brandIndex].name}.${firstBrandKeys[keyIndex]}`,
          success: false
        };
      }
    }
  }

  if (!brandData.every((brand) => JSON.stringify(Object.keys(brand)) === JSON.stringify(Object.keys(firstBrand)))) {
    return { error: 'Every brand object must have the same properties', success: false };
  }

  return { error: null, success: true };
};

export const getBrandKey = (brandName) => {
  return Object.entries(brandNames).find((entry) => {
    return entry[1].includes(brandName);
  })?.[0] ?? null;
};