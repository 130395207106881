import { useEffect, useReducer } from 'react';
import { useDataModel, useLazyDataModel } from '@thd-nucleus/data-sources';
import { ACTION_TYPES, SWATCH_SIZE } from '../statics';
import { reducer } from '../reducer/reducer';
import { isItemOutOfStock, shouldDisplaySwatchCards, isItemUnavailableInStore } from '../super-sku-util';

export const initialState = {
  attributes: [],
  childItemsLookup: [],
  superDuperSku: {},
  isDiscontinued: false,
  specificationGroup: [],
  isSskuClicked: false
};

export const useSuperSku = ({
  disableMediaAndAvailability,
  itemId,
  configId,
  storeId,
  isMiniSwatch,
  hideCards
}) => {
  const opts = {
    variables: {
      itemId,
      storeId,
      configId,
      skipPaintDetails: !configId
    },
    ssr: false
  };
  const { data } = useDataModel('product', opts);
  const {
    availabilityType,
    details,
    identifiers,
    sizeAndFitDetail,
    fulfillment,
    specificationGroup,
    info,
    paintDetails,
  } = data?.product || {};

  const { discontinued, type } = availabilityType || {};

  let isSuperSku = !!identifiers?.isSuperSku;
  let parentId = identifiers?.parentId;
  let brandName = identifiers?.brandName;
  const paintFamily = info?.paintFamily;

  const collectionId = details?.collection?.collectionId;
  const itemSizeAndFitDetail = sizeAndFitDetail;

  const isDiscontinued = !!discontinued;
  const isOutOfStock = isItemOutOfStock(fulfillment, type);
  const isUnavailableInStore = isItemUnavailableInStore(fulfillment, type);

  const [state, dispatch] = useReducer(reducer, initialState);

  const { opname, metadataOpts } = configId
    ? {
      opname: 'metadataPaint',
      metadataOpts: {
        variables: {
          configId,
          storeId,
          paintFamily
        },
        ssr: false
      }
    }
    : {
      opname: 'metadata',
      metadataOpts: {
        variables: {
          parentId
        },
        ssr: false
      }
    };

  const [makeMetadataCall, superSkuData] = useLazyDataModel(opname, metadataOpts);

  useEffect(() => {
    if ((!configId && parentId && isSuperSku && state?.previousParentId !== parentId)
      || (configId && paintFamily)) {
      makeMetadataCall();
    }
  }, [parentId, isSuperSku, state?.previousParentId !== parentId, configId, paintFamily]);

  useEffect(() => {
    if (!superSkuData?.called) {
      return;
    }
    if (configId && !paintFamily) {
      dispatch({ type: ACTION_TYPES.NO_DATA_STATE });
    } else if (superSkuData?.data) {
      const metaDataResponse = superSkuData?.data?.metadata || superSkuData?.data?.metadataPaint;
      if (metaDataResponse) {
        dispatch({
          brandName,
          collectionId,
          disableMediaAndAvailability,
          isDiscontinued,
          isOutOfStock,
          isUnavailableInStore,
          itemId,
          itemSizeAndFitDetail,
          isMiniSwatch,
          info,
          paintDetails,
          parentId,
          response: metaDataResponse,
          specificationGroup,
          type: ACTION_TYPES.LOAD_STATE,
          hideCards
        });
      } else {
        dispatch({ type: ACTION_TYPES.NO_DATA_STATE });
      }
    } else if (superSkuData?.error && !superSkuData?.data) {
      dispatch({ type: ACTION_TYPES.ERROR_STATE });
    }
    if (superSkuData.loading) {
      dispatch({ type: ACTION_TYPES.LOADING_STATE });
    }
  }, [superSkuData?.data, superSkuData]);

  useEffect(() => {
    dispatch({
      type: ACTION_TYPES.UPDATE_ALERT_VALUES,
      isDiscontinued,
      isOutOfStock,
      isUnavailableInStore,
      disableMediaAndAvailability
    });
  }, [data?.product?.itemId]);

  return [state, dispatch];
};

export const getSelectedAttribute = (state, attributeLabel, attributeValues) => {
  const { selectedAttributes } = state;
  const currentAttribute = selectedAttributes.find((attribute) => {
    return attribute.attributeLabel === attributeLabel;
  }) || {};

  let swatchGuid = null;
  if (currentAttribute.attributeValue && currentAttribute.isSwatch) {
    swatchGuid = attributeValues?.find((attributeValue) => attributeValue?.value === currentAttribute.attributeValue)
      ?.swatchGuid;
  }

  return {
    attributeValue: currentAttribute.attributeValue || 'Please Select',
    swatchGuid
  };
};

export const getAttributeType = (
  state,
  channel,
  attribute,
  isOverflowSection,
  hideCards,
  disableMediaAndAvailability,
  large,
  dotComColorEligible = false
) => {
  const { isSwatch } = attribute;
  const isLarge = dotComColorEligible ? large && !isSwatch : large;
  const { childItemsLookup = [] } = state;
  const isCard = isLarge || !!(isSwatch
    && !hideCards
    && shouldDisplaySwatchCards(state, channel)
    && childItemsLookup.length
    && !disableMediaAndAvailability
  );

  if (isOverflowSection) return 'dropdown';
  if (isCard) return 'card';
  if (isSwatch) return 'swatch';
  if (!isOverflowSection && !isSwatch) return 'tile';

  return null;
};

export const getAttributeWidth = (attributeType, miniSwatch) => {
  switch (attributeType) {
  case 'swatch':
    return Number(miniSwatch ? SWATCH_SIZE.XS : SWATCH_SIZE.SM);
  default:
    return 120;
  }
};

export const attributeValueFilter = ({ attribute, attributeValue, enabledTilesInAttribute }) => {
  return (attribute.isDominantAttribute || enabledTilesInAttribute.has(attributeValue.value));
};
