import React from 'react';
import { Carousel } from '@thd-olt-component-react/carousel';
import { node, bool, shape } from 'prop-types';
import './super-sku-carousel.scss';

export const SuperSkuCarousel = (props) => {
  const {
    carouselProps,
    children,
    shouldUseCarousel
  } = props;

  const attributeValues = children?.[0];

  return (
    shouldUseCarousel ? (
      <div className="super-sku-carousel" data-testid="super-sku-carousel">
        <Carousel
          {...carouselProps} // eslint-disable-line react/jsx-props-no-spreading
        >
          {attributeValues}
        </Carousel>
      </div>
    ) : (
      <>{children}</>
    )
  );
};

SuperSkuCarousel.displayName = 'SuperSkuCarousel';

SuperSkuCarousel.propTypes = {
  carouselProps: shape({}),
  children: node.isRequired,
  shouldUseCarousel: bool
};

SuperSkuCarousel.defaultProps = {
  carouselProps: null,
  shouldUseCarousel: false
};
