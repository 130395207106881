import { useContext } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  useCollectionDetailDeprecated,
  useProductDeprecated
} from '@thd-nucleus/data-sources/react/dataModel/migration';

export const useSizeGuide = ({
  isCollection, isSuperSku, itemId, superSkuSizeAndFitDetail, itemSizeAndFitDetail, storeIdFromMock
}) => {
  const {
    store,
    clientStore
  } = useContext(ExperienceContext) || {};

  const { storeId } = clientStore || store || {};

  let data;
  let loading;
  let error;
  const hasItemSizeAndFitDetail = Object.keys(itemSizeAndFitDetail)?.length;

  const {
    data: collectionDetail,
    loading: collectionLoading,
    error: collectionError
  } = useDataModel('collectionSizeAndFit', {
    variables: {
      collectionId: itemId,
      storeId: storeIdFromMock || storeId,
    },
    skip: !isCollection,
    ssr: false
  });
  const {
    data: productData,
    loading: productLoading,
    error: productError
  } = useDataModel('clientOnlyProduct', {
    variables: {
      itemId
    },
    skip: hasItemSizeAndFitDetail || isCollection || isSuperSku,
    ssr: false
  });

  if (isCollection) {
    data = collectionDetail?.collection?.sizeAndFitDetail;
    loading = collectionLoading;
    error = collectionError;
  } else if (isSuperSku) {
    data = superSkuSizeAndFitDetail;
    loading = false;
    error = false;
  } else if (hasItemSizeAndFitDetail) {
    data = hasItemSizeAndFitDetail;
    loading = false;
    error = false;
  } else {
    data = productData?.product?.sizeAndFitDetail;
    loading = productLoading;
    error = productError;
  }

  return { data, loading, error };
};
