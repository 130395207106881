import React, { useEffect, useState } from 'react';
import {
  arrayOf, bool, func, oneOf, shape, string
} from 'prop-types';
import {
  arrayOf as arrayOfType,
  bool as boolType,
  params,
  shape as shapeType,
  string as stringType,
  useLazyDataModel
} from '@thd-nucleus/data-sources';
import {
  ACTION_TYPES,
} from '../../statics';
import { SuperSkuAttribute } from '../super-sku-attribute/super-sku-attribute';
import { SuperSkuAttributeValue } from '../super-sku-attribute-value/super-sku-attribute-value';

export const SuperDuperSku = (props) => {
  const {
    channel,
    handleSdskuAttributeValueClick,
    handleSdskuAttributeOverflowReset,
    handleSdskuAttributeOverflowUpdate,
    superDuperSku: {
      attributes
    } = {}
  } = props;

  const [parentId, setParentId] = useState(null);
  const options = {
    variables: {
      parentId
    },
    ssr: false
  };
  const [makeMetadataCall, superDuperSkuData] = useLazyDataModel('metadata', options);

  useEffect(() => {
    if (parentId) {
      makeMetadataCall();
    }
  }, [parentId]);

  useEffect(() => {
    if (!superDuperSkuData?.called) {
      return;
    }

    if (superDuperSkuData?.data) {
      const { metadata = {} } = superDuperSkuData.data;
      if (metadata?.childItemsLookup) {
        handleSdskuAttributeValueClick(metadata);
      }
    }
  }, [superDuperSkuData?.data, superDuperSkuData]);

  const extractParentId = (superSkuUrl) => superSkuUrl?.split('/')?.pop();

  const { attributeName = '', attributeValues = [], isAvailable = true, isOverflowSection = false } = attributes[0];
  const selectedAttributeValue = attributeValues.find((attributeValue) => attributeValue.selected);
  const attributeType = isOverflowSection ? 'dropdown' : 'tile';

  return (
    <div className="super-duper-sku">
      <SuperSkuAttribute
        attributeName={attributeName}
        attributeValue={selectedAttributeValue?.value}
        attributeType={attributeType}
        handleAttributeOverflowUpdate={handleSdskuAttributeOverflowUpdate}
        handleAttributeOverflowReset={handleSdskuAttributeOverflowReset}
        isOverflowSection={isOverflowSection}
        key={attributeName}
      >
        {(attributeValues || []).map((attributeValue) => {
          const { value, superSkuUrl, selected } = attributeValue;

          return (
            <SuperSkuAttributeValue
              attributeType={attributeType}
              channel={channel}
              isAvailable={isAvailable}
              isSelected={selected}
              key={`${attributeName}-${value}`}
              onClick={() => setParentId(extractParentId(superSkuUrl))}
              value={value}
            />
          );
        })}
      </SuperSkuAttribute>
    </div>
  );
};

SuperDuperSku.displayName = 'SuperDuperSku';

SuperDuperSku.propTypes = {
  channel: oneOf(['desktop', 'mobile']),
  handleSdskuAttributeValueClick: func.isRequired,
  handleSdskuAttributeOverflowReset: func.isRequired,
  handleSdskuAttributeOverflowUpdate: func.isRequired,
  superDuperSku: shape({
    attributes: arrayOf(shape({
      attributeName: string,
      attributeValues: arrayOf(shape({
        selected: bool,
        superSkuUrl: string,
        value: string
      }))
    }))
  }).isRequired,
};

SuperDuperSku.defaultProps = {
  channel: 'desktop'
};

SuperDuperSku.dataModel = {
  metadata: params({ parentId: stringType().isRequired() }).shape({
    attributes: arrayOfType(shapeType({
      attributeName: stringType(),
      attributeValues: arrayOfType(shapeType({
        swatchGuid: stringType(),
        value: stringType()
      })),
      isSwatch: boolType()
    })),
    childItemsLookup: arrayOfType(shapeType({
      attributeCombination: stringType(),
      canonicalUrl: stringType(),
      isItemBackOrdered: boolType(),
      itemId: stringType(),
    }))
  })
};
