import {
  ACTION_TYPES,
  KEY_VALUE_DELIMITER,
  COMBINATION_DELIMITER,
  MAXIMUM_COMBINATIONS_FOR_PRICE_ENDPOINT,
  MAXIMUM_CALLS_FOR_PRICE_ENDPOINT
} from './statics';

export const createSelectedItemAttributes = (state, selectedItem) => {
  const currentlySelected = selectedItem.split(COMBINATION_DELIMITER);
  if (state.attributes[0]?.attributeName === 'Fabric Type') {
    const currentlySelectedFabric = currentlySelected.splice(1, 1);
    currentlySelected.unshift(currentlySelectedFabric[0]);
  }
  return currentlySelected.map((attributeValue, index) => {
    return {
      attributeLabel: (state.attributes[index] || {}).attributeName,
      attributeValue: (attributeValue.indexOf(KEY_VALUE_DELIMITER) >= 0
        ? attributeValue.split(KEY_VALUE_DELIMITER)[1]
        : attributeValue),
      isSwatch: (state.attributes[index] || {}).isSwatch
    };
  });
};

export const reorderAttributesForPatio = (attributeArr) => {
  return [attributeArr[1], attributeArr[0], ...attributeArr.slice(2, 4)];
};

export const determineSelectedAttributes = (state, selected) => {
  const { childItemsLookup } = state;

  if (selected) {
    return createSelectedItemAttributes(state, selected);
  }
  const selectedProduct = childItemsLookup.filter((item) => {
    return item.itemId === state.itemId;
  });

  if (!selectedProduct || !childItemsLookup || childItemsLookup.length === 0) {
    return [];
  }

  return createSelectedItemAttributes(state, selectedProduct[0]?.attributeCombination);
};

export const getMatchedAttributeCombinationDetails = (state) => {
  let currentCombination = state?.selectedAttributes?.map((data) => {
    return data.attributeValue?.trim();
  });
  currentCombination = state.attributes[0].attributeName === 'Fabric Type'
    ? reorderAttributesForPatio(currentCombination)
    : currentCombination;
  currentCombination = currentCombination.join(COMBINATION_DELIMITER);
  return state.childItemsLookup.find((childItem) => {
    return childItem.attributeCombination === currentCombination;
  });
};

export const getAttributeCombination = (selectedAttributes, currentTileInfo) => {
  const newSelectedAttributes = selectedAttributes?.map((attribute) => {
    if (attribute.attributeLabel === currentTileInfo.attributeLabel) {
      return {
        ...attribute,
        attributeValue: currentTileInfo.attributeValue,
      };
    }
    return {
      ...attribute,
    };
  });

  if (newSelectedAttributes.some((attribute) => attribute.attributeLabel === currentTileInfo.attributeLabel)) {
    return newSelectedAttributes;
  }
  return [...newSelectedAttributes, currentTileInfo];
};

const getCombinationsToRank = (state, clickedTile, childItemsLookupFromSdsku) => {
  if (!clickedTile.attributeLabel) {
    return childItemsLookupFromSdsku;
  }
  const availableCombinationsToRank = state.childItemsLookup.filter((childItem) => {
    return childItem.inventory?.isAvailable && (childItem[clickedTile.attributeLabel] === clickedTile.attributeValue);
  });

  if (availableCombinationsToRank.length) {
    return availableCombinationsToRank;
  }

  return state.childItemsLookup.filter((childItem) => {
    return childItem[clickedTile.attributeLabel] === clickedTile.attributeValue;
  });
};

export const getRankedCombinations = (state, combinationsToRank) => {
  let rankedCombinations = [];
  state.attributes.forEach(() => {
    rankedCombinations.push([]);
  });

  if (!state.selectedAttributes?.length && combinationsToRank?.length) {
    rankedCombinations[0].push(combinationsToRank[0]);
    return rankedCombinations;
  }

  const rankedCombos = combinationsToRank.reduce((rankedCombo, combination) => {
    const rank = state.selectedAttributes.reduce((currentRank, attribute) => {
      return combination[attribute.attributeLabel] === attribute.attributeValue
        ? currentRank + 1
        : currentRank;
    }, -1);

    if (rank !== -1) {
      (rankedCombo[rank] || []).push(combination);
    }
    return rankedCombo;
  }, rankedCombinations).reverse();

  if (rankedCombos.find((combo) => combo?.length)) {
    return rankedCombos;
  }
  return [[combinationsToRank[0]]];
};

const getHighestRankedCombination = (rankedCombos) => {
  const highestRankedCombo = rankedCombos.find((combo) => {
    return combo.length > 0;
  }) || [];
  const firstCombination = highestRankedCombo.pop() || {};
  return firstCombination;
};

export const findClosestSku = (state, clickedTile, childItemsLookupFromSdsku) => {
  const combinationsToRank = getCombinationsToRank(state, clickedTile, childItemsLookupFromSdsku);
  const rankedCombinations = getRankedCombinations(state, combinationsToRank);
  return getHighestRankedCombination(rankedCombinations);
};

export const getSelectedAttributesFromClosestSku = (state, clickedTile) => {
  const selected = findClosestSku(state, clickedTile).attributeCombination;
  return determineSelectedAttributes(state, selected);
};

export const getSelectedAttributes = (state, clickedTile) => {
  if (!getMatchedAttributeCombinationDetails(state)) {
    const selectedAttributes = getSelectedAttributesFromClosestSku(state, clickedTile);
    if (selectedAttributes.length === 0) {
      return {
        ...state,
        impossibleState: true
      };
    }
    const hasASingleAttribute = state?.attributes?.length === 1;
    return {
      ...state,
      jumpedState: !hasASingleAttribute,
      selectedAttributes
    };
  }

  return {
    ...state,
    jumpedState: false,
    impossibleState: false
  };
};

export const isLookupLengthLessThanMaximum = ({ childItemsLookup = [] }) => {
  return childItemsLookup.length <= MAXIMUM_COMBINATIONS_FOR_PRICE_ENDPOINT * MAXIMUM_CALLS_FOR_PRICE_ENDPOINT;
};

export const shouldDisplayInventory = (state) => {
  const { disableMediaAndAvailability } = state;
  return (!disableMediaAndAvailability);
};

export const getProductInformation = (state) => {
  let productInformation = {};
  const isPatio = state?.selectedAttributes?.[0]?.attributeLabel === 'Fabric Type';
  const currentCombination = state.selectedAttributes.map((data) => {
    return data.attributeValue?.trim();
  }).join(COMBINATION_DELIMITER);
  state.childItemsLookup.forEach((childItem) => {
    let attributeCombinationJoin = childItem.attributeCombination;
    if (isPatio) {
      let childItemSplit = childItem.attributeCombination.split(COMBINATION_DELIMITER);
      attributeCombinationJoin = reorderAttributesForPatio(childItemSplit).join(COMBINATION_DELIMITER);
    }
    if (attributeCombinationJoin === currentCombination) {
      productInformation.itemId = childItem.itemId;
      productInformation.canonicalUrl = childItem.canonicalUrl;
      productInformation.isAvailable = shouldDisplayInventory(state) ? childItem.inventory?.isAvailable : true;
    }
  });
  return {
    ...productInformation
  };
};

export const getIndexofUniqueAttribute = (allItemsThatCanBeReachedInOneClick, attributes) => {
  let result = null;
  for (let index = 0; index < attributes?.length; index += 1) {
    let { attributeName } = attributes[index];
    let testValue;
    for (let innerIndex = 0; innerIndex < allItemsThatCanBeReachedInOneClick?.length; innerIndex += 1) {
      let item = allItemsThatCanBeReachedInOneClick[innerIndex];
      if (innerIndex === 0) {
        testValue = item[attributeName];
      } else if (item[attributeName] !== testValue && result === null) {
        return index;
      }
    }
  }
  return 0;
};

export const enabledAttributeValues = (attributes, allItemsThatCanBeReachedInOneClick) => {
  const enabledAttributes = {};

  allItemsThatCanBeReachedInOneClick.forEach((item) => {
    attributes.forEach(({ attributeName }) => {
      if (!enabledAttributes[attributeName]) {
        enabledAttributes[attributeName] = new Set();
      }
      enabledAttributes[attributeName].add(item[attributeName]);
    });
  });

  return enabledAttributes;
};

export const getAvailableAttributeValues = (state, allItemsThatCanBeReachedInOneClick) => {
  const { selectedAttributes, attributes } = state;
  const uniqueAttributeIndex = getIndexofUniqueAttribute(allItemsThatCanBeReachedInOneClick, attributes);
  const selectedAnchorAttributeLabel = selectedAttributes[uniqueAttributeIndex]?.attributeLabel;
  const selectedAnchorAttributeValue = selectedAttributes[uniqueAttributeIndex]?.attributeValue;

  let availableAttributeValues = {};

  allItemsThatCanBeReachedInOneClick.forEach((item) => {
    if (isLookupLengthLessThanMaximum(state)) {
      attributes.forEach(({ attributeName }) => {
        if (attributeName === selectedAnchorAttributeLabel && item?.inventory?.isAvailable) {
          if (!availableAttributeValues[attributeName]) {
            availableAttributeValues[attributeName] = new Set();
          }
          availableAttributeValues[attributeName].add(item[attributeName]);
        }
      });
    } else {
      attributes.forEach(({ attributeName }) => {
        if (attributeName === selectedAnchorAttributeLabel) {
          if (!availableAttributeValues[attributeName]) {
            availableAttributeValues[attributeName] = new Set();
          }
          availableAttributeValues[attributeName].add(item[attributeName]);
        }
      });
    }
    if (item[selectedAnchorAttributeLabel] === selectedAnchorAttributeValue) {
      attributes.forEach(({ attributeName }) => {
        if (!availableAttributeValues[attributeName]) {
          availableAttributeValues[attributeName] = new Set();
        }
        availableAttributeValues[attributeName].add(item[attributeName]);
      });
    }
  });
  return availableAttributeValues;
};

export const getEnabledAndAvailableAttributeValues = (state) => {
  let allItemsThatCanBeReachedInOneClick = state.childItemsLookup;
  let enabledAndAvailableAttributeValues = {
    enabled: {},
    available: {}
  };

  enabledAndAvailableAttributeValues.enabled = enabledAttributeValues(
    state?.attributes,
    allItemsThatCanBeReachedInOneClick,
  );

  if (shouldDisplayInventory(state)) {
    enabledAndAvailableAttributeValues.available = getAvailableAttributeValues(
      state,
      allItemsThatCanBeReachedInOneClick,
    );
  }
  return enabledAndAvailableAttributeValues;
};

export const getMinifiedString = (data) => {
  return data.replace(/ |;/gi, '_');
};

export const getUnMinifiedString = (data) => {
  return data.toString().replace(/_/gi, ' ');
};

const hasSwatchOnFirstAttribute = (attributes = []) => {
  return !!attributes[0]?.isSwatch;
};

export const isOverflowSection = (attribute = {}) => {
  return !!(attribute.scrollHeight > attribute.clientHeight);
};

export const shouldDisplaySwatchCards = (state, channel) => {
  const { attributes = [] } = state;
  return !!(channel === 'mobile'
    && isLookupLengthLessThanMaximum(state)
    && hasSwatchOnFirstAttribute(attributes)
  );
};

export const shouldDisplayTooltips = (state, channel) => {
  const isDesktop = channel === 'desktop';
  return (isDesktop
    && isLookupLengthLessThanMaximum(state)
  );
};

export const getProcessedInventoryData = (onlineInventory, storeInventory, isItemBackOrdered) => {
  return {
    isAvailable: ((onlineInventory?.enableItem && onlineInventory?.totalQuantity > 0)
      || (storeInventory?.enableItem && storeInventory?.totalQuantity > 0)
      || isItemBackOrdered)
  };
};

export const formatPrice = (rawPrice) => {
  // TODO: this should be a common/shared utility function https://github.homedepot.com/thd-olt-functional/utils
  const addCommas = () => parseFloat(rawPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const format = () => {
    let parsedPrice = parseFloat(rawPrice).toFixed(2).toString().split('.');
    let formattedPrice = '';
    let dollars = parsedPrice[0];
    let cents = parsedPrice[1];
    let lessThanDollar = false;

    if (dollars < 1) {
      lessThanDollar = true;
      formattedPrice = cents + '¢';
    } else {
      formattedPrice = '$' + addCommas();
    }

    return {
      formatted: formattedPrice,
      dollars,
      cents,
      lessThanDollar
    };
  };

  return {
    addCommas,
    format
  };
};

export const getProcessedPricingData = (pricing, isAuthenticated) => {
  const {
    value, original, message: pricingMessage, mapAboveOriginalPrice, alternate, unitOfMeasure: UOM, mapDetail = {}
  } = pricing || {};
  const { value: unitValue, caseUnitOfMeasure: caseUOM } = alternate?.unit || {};

  let message = !value && !original ? 'Click Option to View Price' : '';
  let strikeThroughPriceForMap = false;
  let minAdvertisedPrice = false;
  let rawPrice = value;

  // mapPolicy exist
  if (mapDetail) {
    if (mapDetail?.mapPolicy) {
      if (mapDetail?.mapPolicy === 'showSpecialPriceIfAuth') {
        if (!isAuthenticated) {
          // if 'showSpecialPriceIfAuth' and user is not authenticated
          strikeThroughPriceForMap = original && value && original !== value;
          message = 'Sign in for The Home Depot price';
          rawPrice = value || original;
        }
      } else {
        // others policy
        minAdvertisedPrice = true;
        strikeThroughPriceForMap = original !== null;
        rawPrice = original;
        message = 'See Price in Cart';
      }
    }
  } else if (mapAboveOriginalPrice || /see final price in cart/i.test(pricingMessage)) {
    // not existing mapPolicy or mapPolicy is null, but we have mapAboveOriginalPrice (old logic)
    minAdvertisedPrice = mapAboveOriginalPrice !== null;
    strikeThroughPriceForMap = !mapAboveOriginalPrice && /see final price in cart/i.test(pricingMessage);
    message = 'See Price in Cart';
    rawPrice = original;
  }
  const formattedPrice = rawPrice ? formatPrice(rawPrice)?.format() : null;
  return {
    price: formattedPrice,
    isSpecialPrice: !!original,
    shouldHidePriceForMap: minAdvertisedPrice,
    message,
    strikeThroughPriceForMap,
    unitValue,
    UOM,
    caseUOM
  };
};

export const getFormattedChildItemsLookup = (attributes, childItemsLookup) => {
  const attributeNames = attributes.map((attribute) => attribute.attributeName);

  return childItemsLookup.map((item) => {
    let combination = item.attributeCombination.split(COMBINATION_DELIMITER);
    if (attributes[0] && attributes[0]?.attributeName === 'Fabric Type') {
      combination = reorderAttributesForPatio(combination);
    }
    const flattenedCombinations = combination.reduce((acc, combo, index) => {
      acc[attributeNames[index]] = combo;
      return acc;
    }, {});
    return {
      ...item,
      ...flattenedCombinations
    };
  });
};

export const getSwatchCardCombinationDetails = (state, attributeLabel, attributeValue) => {
  const currentCard = {
    attributeLabel,
    attributeValue
  };

  return findClosestSku(state, currentCard);
};

export const isItemOutOfStock = (fulfillment, type) => {
  const { isOutOfStockOnline } = fulfillment || {};
  return isOutOfStockOnline && type === 'Online';
};

export const isItemUnavailableInStore = (fulfillment, type) => {
  const { isOutOfStockOnline } = fulfillment || {};
  return isOutOfStockOnline && type === 'Store Only';
};

export const syncAttributes = (state, dispatch, domPath) => {
  const collectionListPath = 'CollectionList[0]';
  if (domPath.indexOf(collectionListPath) !== -1
    && typeof window !== 'undefined'
    && window.LIFE_CYCLE_EVENT_BUS
  ) {
    window.LIFE_CYCLE_EVENT_BUS.on('super-sku.pass-to-collection', (data) => {
      const { parentId } = state;
      if (data?.output?.parentId === parentId) {
        const { output } = data;
        const { attributeLabel, attributeValue } = output;
        dispatch({
          type: ACTION_TYPES.SELECT, attributeLabel, attributeValue
        });
      }
    });
  }
};

export const shouldDisplayAlerts = (state) => {
  const {
    enabledAndAvailableAttributeValues = {},
    errorState = false,
    isMiniSwatch = false,
    jumpedState = false,
    impossibleState = false,
    unavailableState = false,
    isDiscontinued = false,
    isOutOfStock = false
  } = state || {};

  const availableAttributes = enabledAndAvailableAttributeValues?.available || {};
  const attributes = Object.values(availableAttributes);
  return (
    attributes.some((attribute) => attribute.size !== 0)
    && !errorState
    && !isMiniSwatch
    && (jumpedState || impossibleState || unavailableState || isDiscontinued || isOutOfStock)
  );
};

const getPaintTypeImage = (attributeValue) => {
  switch (attributeValue) {
  case 'Interior Paint':
    return 'paint/paint-type-interior.svg';
  case 'Exterior Paint':
    return 'paint/paint-type-exterior.svg';
  default:
    return null;
  }
};

const getBehrImage = (attributeValue) => {
  switch (attributeValue) {
  case 'BEHR MARQUEE':
    return 'brand-logos/behr-marquee-black.svg';
  case 'BEHR ULTRA':
    return 'brand-logos/behr-ultra-blk.svg';
  case 'BEHR PREMIUM PLUS':
    return 'brand-logos/behr-premium-plus-black.svg';
  case 'BEHR DYNASTY':
    return 'brand-logos/behr-dynasty-blk.svg';
  default:
    return 'paintReorder/behrLogoScaling.svg';
  }
};

const getPPGGliddenImage = (attributeValue) => {
  switch (attributeValue) {
  case 'Glidden Diamond':
    return 'brand-logos/glidden-diamond.svg';
  case 'Glidden Premium':
    return 'brand-logos/glidden-premium.svg';
  case 'Glidden Essentials':
    return 'brand-logos/glidden-essentials.svg';
  case 'Glidden HydroResist':
  case 'Glidden Maintenance':
  case 'SUPERIOR':
  case 'Glidden':
  case 'Glidden Porch and Floor':
  case 'Glidden Professional':
    return 'paintReorder/gliddenLogoScaling.svg';
  case 'PPG TIMELESS':
    return 'brand-logos/ppg-timeless.svg';
  case 'PPG Diamond':
    return 'brand-logos/ppg-diamond.svg';
  default:
    return 'paintReorder/ppg-logo-config-panel.svg';
  }
};

const getSheenImage = (attributeValue) => {
  switch (attributeValue) {
  case 'Flat Enamel':
  case 'Textured Low Lustre':
  case 'Low Lustre':
  case 'Ultra Flat':
  case 'Flat/Matte':
    return 'paint/flat-matte-tile.png';
  case 'Eggshell':
    return 'paint/eggshell-tile.png';
  case 'Natural Satin':
  case 'Satin':
    return 'paint/satin-tile.png';
  case 'Semi-Gloss':
    return 'paint/semi-gloss-tile.png';
  case 'Gloss':
  case 'High-Gloss':
    return 'paint/high-gloss-tile.png';
  default:
    return null;
  }
};

const getCanSizeImage = (attributeValue) => {
  switch (attributeValue) {
  case '13 Ounce':
  case '10 Ounce':
  case '8 Ounce':
  case '8 Ounce Sample':
    return 'paint/sample-bucket.svg';
  case '1 Quart':
    return 'paint/quart-bucket.svg';
  case '3 Gallon':
  case '5 Gallon':
    return 'paint/5-gallon-bucket.svg';
  case '2 Gallon':
  case '118 Ounce':
  case '1 Gallon':
  default:
    return 'paint/1-gallon-bucket.svg';
  }
};

export const getPaintContainerSize = (value) => {
  let containerSize;

  switch (value) {
  case '13 Ounce':
  case '10 Ounce':
  case '8 Ounce':
  case '8 Ounce Sample':
    containerSize = 'sample';
    break;
  case '1 Quart':
    containerSize = 'quart';
    break;
  case '3 Gallon':
  case '5 Gallon':
    containerSize = '5gallon';
    break;
  case '2 Gallon':
  case '118 Ounce':
  case '1 Gallon':
    containerSize = 'gallon';
    break;
  default:
    break;
  }

  return containerSize;
};

const getBrandImage = (attributeValue, paintBrand) => {
  switch (paintBrand) {
  case 'Behr':
    return getBehrImage(attributeValue);
  case 'PPG - Glidden':
    return getPPGGliddenImage(attributeValue);
  default:
    return null;
  }
};

export const getPaintImageUrl = (attributeValue, attributeName, paintBrand) => {
  let imagePath;
  switch (attributeName) {
  case 'Paint Type':
    imagePath = getPaintTypeImage(attributeValue);
    break;
  case 'Brand Name':
    imagePath = getBrandImage(attributeValue, paintBrand);
    break;
  case 'Sheen':
    imagePath = getSheenImage(attributeValue);
    break;
  case 'Container Size':
    imagePath = getCanSizeImage(attributeValue);
    break;
  default:
    break;
  }
  return imagePath ? `https://assets.thdstatic.com/images/v1/${imagePath}` : undefined;
};

export const rgbToHex = ({ red, green, blue }) => {
  return ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1); // eslint-disable-line no-bitwise
};

export const getTitle = (attributeName, type) => {
  const titles = {
    'Color/Finish': 'Paint Types',
    'Paint Type': 'Paint Types',
    Sheen: 'Sheen Options',
    'Brand Name': `Brand Options (${type})`,
  };

  return titles[attributeName] || '';
};
