import { Brand } from './components/brand/Brand';
import { PaintType } from './components/paint-type/PaintType';
import { Sheen } from './components/sheen/Sheen';
import { ContainerSize } from './components/container-size/ContainerSize';

export const PaintModalContent = {
  Brand,
  PaintType,
  Sheen,
  ContainerSize
};
